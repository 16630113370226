/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import { EventType } from 'src/dto/pmo/eventtype';
import {
    DTO, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { Event_v1 } from './event-v1';

export interface Issue_v1 extends Event_v1, DTOMethods<Event_v1> {
    eventType: number;
    startDate: Date;
    endDate: Date;
    status: string;
    priority: number;
    createdDate: Date;
    completedDate: Date;
    progress: number;
    isReadOnly: boolean;
    files: EventAttachment[];
    resolvedUsers: Attachment[];
}
export const Issue_v1: DTOMetaData & DTOClassMethods<Issue_v1> = {
    _label:'Issues',
    _dto: 'Issue_v1',
    _type: 'issue',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: EventType.ISSUE },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
    ],
    _related: [
    ],
    _endpoint: '/api/eda/v2.0/issue/v1.0',
    _icon: './assets/dtos/issue.svg',
    _docLink: '/wiki/elevate/Assets/issue/',
    _depth: 1,
    endpointAPI: new BoundEndpointAPI<Issue_v1>('Issue_v1', DTO),
    from: (obj: any): Issue_v1 => {
        return Object.setPrototypeOf(obj, Issue_v1._prototype);
    },
    select: (p: any = ''): any => {
        return Issue_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => Issue_v1.from(a) as Issue_v1)
            : Issue_v1.from(res)
    )}
}
